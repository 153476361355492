import { v4 as uuidv4 } from "uuid";
import get from "lodash/get";

// constants
import { TOP_BAR_CATEGORIES } from "../constants";
import moment from "moment";

// Interaction Events
const BUTTON_INTERACTION = "Button Interaction";

const getItemCatalogsData = (item = {}, breadcrumbs = {}) => {
  let itemAvailability = "For Sale";
  if (item.isSold) {
    itemAvailability = "Sold";
  } else if (item.isReserved) {
    itemAvailability = "Reserved";
  }

  const itemMaterials = [];
  const itemOrigins = [];

  (item.catalogsElements || []).forEach((el) => {
    if (el.catalogTitle === "Origin") {
      itemOrigins.push(el);
    }
    if (el.catalogTitle === "Material") {
      itemMaterials.push(el);
    }
  });

  return {
    itemAvailability,
    dataLayerPeriod: (item.periods || [])
      .map((period) => period.title)
      .join(", "),
    dataLayerMaterial: itemMaterials
      .map((material) => material.title)
      .join(", "),
    dataLayerOrigin: itemOrigins.map((origin) => origin.title).join(", "),
    dataLayerArtisans: (item.artisans || [])
      .map((artisan) => artisan.name)
      .join(", "),
    dealerName: get(item, "dealer.businessName", ""),
    category: get(breadcrumbs, "[0].title", ""),
    subCategory: get(breadcrumbs, "[1].title", ""),
  };
};

const QUESTIONS = [
  {
    name: "WHAT IS THE DIFFERENCE BETWEEN BUYING ANTIQUE AND VINTAGE?",
    text:
      "Authentic antique finds are over 100 years old and are rare, as they are no longer produced. " +
      "These antique treasures include those from the Georgian, Victorian, and Edwardian eras. " +
      "Vintage items are those aged 50 to 100 years old.",
  },
  {
    name: "HOW DO I KNOW IF ANTIQUES FOR SALE ARE VALUABLE?",
    text:
      "If you decide to buy antiques UK dealers are offering, look for small marks and signatures that are " +
      "often placed in an obscure spot of the piece. It’s also worth noting that every antique item is " +
      "created according to the style of the period during which it was created, so you have to research " +
      "about the period that interests you, especially when you buy antiques online.",
  },
  {
    name: "WHAT KINDS OF ANTIQUES ARE WORTH MONEY?",
    text:
      "The items to start looking at in antiques stores are furniture pieces, as some are extremely rare. " +
      "Other antiques worth splurging on include art, jewellery, and ceramics, among others. " +
      "A reputable antique website provides a price range for each antique for sale, " +
      "giving you an idea of how lucrative this type of investment can be.",
  },
  {
    name: "WHAT ARE THE MOST SOUGHT AFTER ANTIQUES?",
    text:
      "UK antique collectors most frequently buy antique furniture, silverware, watches, clocks, mirrors, " +
      "chairs and more. Should you decide to shop antiques online, UK dealers have to ensure the store or dealer " +
      "that is selling antiques is an established one, to avoid ending up with counterfeit items.",
  },
];

export const HOME_SCHEMA_MARKUP_SCRIPT = `[
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Love Antiques",
    "url": "https://www.loveantiques.com/",
    "logo": "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/loginLALogo.svg",
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "customer support",
      "telephone": "+44 20 8045 3745",
      "email": "info@loveantiques.com"
    },
    "sameAs": [
      "https://www.facebook.com/loveantiquescom",
      "https://twitter.com/loveantiques",
      "https://www.pinterest.com/loveantiquescom/",
      "https://www.instagram.com/loveantiques_com/",
      "https://www.youtube.com/channel/UCzObXljtikONwkvmmhBeqJQ"
    ]
  },
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [${QUESTIONS.map(
  ({ name, text }) => `
      {
        "@type": "Question",
        "name": "${name}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${text}"
        }
      }`
).join(",")}
    ]
  }
]`;

export const ORGANISATION_SCHEMA_SCRIPT = `
{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Love Antiques",
  "url": "https://www.loveantiques.com/",
  "logo": "https://storage-lant-prd.s3.eu-west-2.amazonaws.com/loginLALogo.svg",
  "contactPoint": {
    "@type": "ContactPoint",
    "contactType": "customer support",
    "telephone": "+44 20 8045 3745",
    "email": "info@loveantiques.com"
  },
  "sameAs": [
    "https://www.facebook.com/loveantiquescom",
    "https://twitter.com/loveantiques",
    "https://www.pinterest.com/loveantiquescom/",
    "https://www.instagram.com/loveantiques_com/",
    "https://www.youtube.com/channel/UCzObXljtikONwkvmmhBeqJQ"
  ]
}
`;

export const returnItemProductScript = (itemData) => {
  const ukDeliveryOption = itemData.deliveryOptions
    ? itemData.deliveryOptions.find(({ region }) => region === "UK") || {}
    : {};

  const categoryTitle = itemData?.categories?.[0]?.title;
  const category = TOP_BAR_CATEGORIES.find(
    ({ title }) => title === categoryTitle
  );

  const name = itemData.title
    ? itemData.title
      .replace(/['"]+/g, "")
      .replace(/\s+/g, " ")
      .trim()
      .slice(0, 150)
    : itemData.url?.trim()?.slice(0, 150);

  const image = itemData?.images?.[0]?.url;

  const description = itemData.description
    ? itemData.description.replace(/['"]+/g, "").replace(/\s+/g, " ")
    : "";

  const materials = itemData.catalogsElements
    ?.filter(({ catalogUrl }) => catalogUrl === "material")
    ?.map(({ title }) => title)
    ?.filter(Boolean);

  const shippingPrice =
    (!ukDeliveryOption.deliveryPriceIncluded && ukDeliveryOption.price) || 0;

  const lastDayOfTheYear = moment().endOf("year").format("YYYY-MM-DD");

  return `{
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "${name || categoryTitle || ""}",
    "image": "${image || category?.categoryImage || ""}",
    "url": "https://www.loveantiques.com/items/listings/${itemData.url}",
    "description": "${
      description?.length > 5000
        ? `${description.slice(0, 4997)}...`
        : description
    }",
    "sku": "${itemData.ref ? itemData.ref : ""}",
    "offers": {
      "@type": "Offer",
      "priceCurrency": "${
        itemData.currency ? itemData.currency.currency : "GBP"
      }",
      "price": "${itemData?.price || 0}",
      "priceValidUntil": "${lastDayOfTheYear}",
      "availability": "${
        itemData.isSold
          ? "https://schema.org/SoldOut"
          : "https://schema.org/InStock"
      }",
      "seller": {
        "@type": "Organization",
        "name": "Love Antiques"
      }${
        !ukDeliveryOption.quoteProvidedOnRequest
          ? `,
      "shippingDetails": {
        "@type": "OfferShippingDetails",
        "shippingRate": {
          "@type": "${shippingPrice ? "MonetaryAmount" : "PriceSpecification"}",
          "currency": "${itemData.currency ? itemData.currency.currency : ""}",
          "value": "${shippingPrice}",
          "maxValue": "${shippingPrice}"
        },
        "shippingDestination": {
          "@type": "DefinedRegion",
          "addressCountry": "GB"
        }
      }`
          : ""
      }
    }${
      materials?.length > 0
        ? `,
      "material": "${materials.join(", ")}"`
        : ""
    }
  }`;
};

export const returnEventAddressScript = ({
  businessName,
  address,
  images,
  nextEvent,
}) => {
  const eventSchema = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: businessName,
    startDate: nextEvent?.fromDate,
    endDate: nextEvent?.toDate,
    eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
    eventStatus: "https://schema.org/EventScheduled",
    description: nextEvent?.description,
    location: {
      "@type": "Place",
      name: `${businessName} Location`,
      ...(address && {
        address: {
          "@type": "PostalAddress",
          streetAddress: address.address,
          addressLocality: address.city,
          addressCountry: address.country,
        },
      }),
    },
    ...(images && {
      image: images.map((image) => image.url),
    }),
    offers: {
      "@type": "Offer",
      url: nextEvent?.buyTicketLink || window.location.href,
      price: nextEvent?.admission || "0",
      availability: "https://schema.org/InStock",
      validFrom: nextEvent?.fromDate,
    },
    performer: {
      "@type": "PerformingGroup",
      name: `${businessName} Performers`,
    },
  };

  return JSON.stringify(eventSchema);
};

export const returnBreadcrumbScript = (breadcrumbsData = []) => {
  if (!breadcrumbsData.some((breadcrumbItem) => !breadcrumbItem.title)) {
    return `
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [${breadcrumbsData.map(
      (item, index) => `{
      "@type": "ListItem",
      "position": ${index + 1},
      "name": "${item.title ? item.title : ""}",
      "item": "${
        item.url
          ? `${process.env.REACT_APP_URL}/${item.url}`
          : process.env.REACT_APP_URL
      }"
    }`
    )}]
}`;
  }
};

export const returnItemPageDataLayer = (item, breadcrumbs) => {
  const {
    itemAvailability,
    dataLayerPeriod,
    dataLayerMaterial,
    dataLayerOrigin,
    dataLayerArtisans,
    dealerName,
    category,
    subCategory,
  } = getItemCatalogsData(item, breadcrumbs);

  return `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "${
    typeof item.title === "string" ? item.title.replaceAll("\"", "'") : ""
  }",
  pageUrl: "${item.url ? `/items/listings/${item.url}` : ""}",
  dealerName: "${dealerName}",
  availability: "${itemAvailability}",
  period: "${dataLayerPeriod}",
  material: "${dataLayerMaterial}",
  origin: "${dataLayerOrigin}",
  artisan: "${dataLayerArtisans}",
  category: "${category}",
  subCategory: "${subCategory}"
})`;
};

export const returnThankYouPageDataLayer = (item = {}, breadcrumbs = []) => `
window.dataLayer.push({
  event: 'GAVPV',
  pageName: "Thank You For Your Order",
  pageUrl: "/thank-you",
  currencyCode: "${item.currency.currency}",
  transactionId: '${uuidv4()}',
  transactionTotal: ${item.price},
  transactionProducts: [{
    sku: '${item.ref}',
    name: '${item.title}',
    category: '${breadcrumbs.map(
  (breadcrumb, index) => `${index !== 0 ? ">" : ""} ${breadcrumb.title}`
)}',
    price: ${item.price},
    quantity: 1
  }]
})`;

export const returnCategoryPageDataLayer = (category) => `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "Antique ${category.title ? category.title : ""} for Sale Online",
  pageUrl: "${category.url ? `/${category.url}` : ""}",
  category: "${category.title ? category.title : ""}",
})`;

export const returnSubcategoryPageDataLayer = (subcategory, category) => `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "Antique ${
    subcategory.title ? subcategory.title : ""
  } for Sale Online",
  pageUrl: "${subcategory.url ? `/${subcategory.fullUrl}` : ""}",
  category: "${category ? category.title : ""}",
  subCategory: "${subcategory ? subcategory.title : ""}"
})
`;

export const returnSimilarCategoryPageDataLayer = (
  similarCategory,
  breadcrumbs
) => `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "Antique ${
    similarCategory.title ? similarCategory.title : ""
  } for Sale Online",
  pageUrl: "${similarCategory.url ? `/${similarCategory.url}` : ""}",
  category: "${breadcrumbs && breadcrumbs[0] ? breadcrumbs[0].title : ""}",
  subCategory: "${
    breadcrumbs && breadcrumbs.length > 2 && breadcrumbs[1]
      ? breadcrumbs[1].title
      : ""
  }",
  subSubCategory: "${similarCategory ? similarCategory.title : ""}"
})
`;

export const returnCategorySearchDataLayer = (
  category,
  breadcrumbs,
  location,
  isSimilarCategory
) => {
  let subcategoryTitle = "";
  if (category && category.parent) {
    subcategoryTitle = category.title;
  }
  if (breadcrumbs.length > 2) {
    subcategoryTitle = breadcrumbs[1].title;
  }

  let subSubCategoryTitle = "";
  if ((category && category.isSeoCategory) || isSimilarCategory) {
    subSubCategoryTitle = category.title;
  }

  return `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "Antique ${category ? category.title : ""} - Search",
  pageUrl: "${location ? `${location}&searchtype=Category` : ""}",
  category: "${breadcrumbs && breadcrumbs[0] ? breadcrumbs[0].title : ""}",
  subCategory: "${subcategoryTitle ? subcategoryTitle : ""}",
  subSubCategory: "${subSubCategoryTitle ? subSubCategoryTitle : ""}",
})`;
};

export const returnProductSearchDataLayer = (item, location, breadcrumbs) => {
  let itemAvailability = "For Sale";
  if (item.isReserved) {
    itemAvailability = "Reserved";
  }

  const {
    dataLayerPeriod,
    dataLayerMaterial,
    dataLayerOrigin,
    dataLayerArtisans,
    dealerName,
    category,
    subCategory,
  } = getItemCatalogsData(item, breadcrumbs);

  return `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "Antique ${item.title ? item.title : ""} for Sale Online",
  pageUrl: "${location ? `${location}&searchtype=product` : ""}",
  dealerName: "${dealerName}",
  availability: "${itemAvailability}",
  period: "${dataLayerPeriod}",
  material: "${dataLayerMaterial}",
  origin: "${dataLayerOrigin}",
  artisan: "${dataLayerArtisans}",
  category: "${category}",
  subCategory: "${subCategory}"
})`;
};

export const returnFreeSearchDataLayer = (location) => `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "Search Results",
  pageUrl: "${location ? `${location}&searchtype=freesearch` : ""}",
})
`;

export const returnAddItemToFavouritesDataLayer = () => {
  return window.dataLayer.push({
    event: "GAEvents",
    gaEventCategory: BUTTON_INTERACTION,
    gaEventAction: "Click",
    gaEventLabel: "Add to Favourites",
  });
};

export const returnAskQuestionModalDataLayer = (
  item,
  breadcrumbs,
  event = "AskAQuestion-Form"
) => {
  const {
    dataLayerPeriod,
    dataLayerMaterial,
    dataLayerOrigin,
    dataLayerArtisans,
    dealerName,
    category,
    subCategory,
    itemAvailability,
  } = getItemCatalogsData(item, breadcrumbs);

  return window.dataLayer.push({
    event: "GAVPV",
    pageName: `Enquiry Form - ${item.title || ""}`,
    pageUrl: `${item.url ? `/items/listings/${item.url}` : ""}/${event}`,
    dealerName: dealerName,
    availability: `${itemAvailability || ""}`,
    period: dataLayerPeriod,
    material: dataLayerMaterial,
    origin: dataLayerOrigin,
    artisan: dataLayerArtisans,
    category: category,
    subCategory: subCategory,
  });
};

export const returnOnRequestCallbackDataLayer = (
  item,
  breadcrumbs,
  event = "RequestACallback-Form"
) => {
  const {
    itemAvailability,
    dataLayerPeriod,
    dataLayerMaterial,
    dataLayerOrigin,
    dataLayerArtisans,
    dealerName,
    category,
    subCategory,
  } = getItemCatalogsData(item, breadcrumbs);

  return window.dataLayer.push({
    event: "GAVPV",
    pageName: `Request a Call Back - ${item.title ? item.title : ""}`,
    pageUrl: `${item.url ? `/items/listings/${item.url}` : ""}/${event}`,
    dealerName: dealerName,
    availability: itemAvailability ? itemAvailability : "",
    period: dataLayerPeriod,
    material: dataLayerMaterial,
    origin: dataLayerOrigin,
    artisan: dataLayerArtisans,
    category: category,
    subCategory: subCategory,
  });
};

export const returnOnShowDealerPhoneDataLayer = (dealerBusinessName) => {
  return window.dataLayer.push({
    event: "GAEvents",
    gaEventCategory: BUTTON_INTERACTION,
    gaEventAction: "Click",
    gaEventLabel: `${
      dealerBusinessName ? `Phone Call - ${dealerBusinessName}` : ""
    }`,
  });
};

export const returnSubscribeToCategoryDataLayer = () => {
  return window.dataLayer.push({
    event: "GAEvents",
    gaEventCategory: BUTTON_INTERACTION,
    gaEventAction: "Click",
    gaEventLabel: "SubscribeForUpdates",
  });
};

export const catalogPageDataLayer = ({
  catalogKey,
  catalogName,
  pageUrl,
  pageName,
}) => `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "${pageName}",
  pageUrl: "${pageUrl}",
  ${catalogKey}: "${catalogName}"
});
`;

export const catalogSearchPageDataLayer = ({
  catalogKey,
  catalogName,
  pageUrl,
  pageName,
}) => `
window.dataLayer.push({
  event: "GAVPV",
  pageName: "${pageName}",
  pageUrl: "${pageUrl}&searchtype=${catalogKey}",
  ${catalogKey}: "${catalogName}"
});
`;
